import { initConfig } from '../config'
import { GetAddressesParams } from '../types/api'
import { getOptions } from '../utils/requests'

const config = initConfig()
const { baseUrl } = config.api

export const getAddressesRequest = (params: GetAddressesParams): [RequestInfo, RequestInit] => {
  const { address, latitude, longitude } = params

  const url = new URL(`${baseUrl}address`)
  const queryParams = url.searchParams

  if (address) queryParams.set('address', address)
  if (latitude) queryParams.set('latitude', latitude)
  if (longitude) queryParams.set('longitude', longitude)

  const info: RequestInfo = url.toString()
  const init: RequestInit = getOptions()

  return [info, init]
}
