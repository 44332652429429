import { initConfig } from '../config'
import { GetPlacesParams } from '../types/api'
import { getOptions } from '../utils/requests'

const config = initConfig()
const { baseUrl } = config.api

export const getPlacesRequest = (params: GetPlacesParams): [RequestInfo, RequestInit] => {
  const { cursor, keyword, latitude, longitude, radius, type } = params

  const url = new URL(`${baseUrl}place`)
  const queryParams = url.searchParams

  if (cursor) queryParams.set('cursor', cursor)
  if (keyword) queryParams.set('keyword', keyword)
  if (latitude) queryParams.set('latitude', latitude)
  if (longitude) queryParams.set('longitude', longitude)
  if (radius) queryParams.set('radius', radius)
  if (type) queryParams.set('type', type)

  const info: RequestInfo = url.toString()
  const init: RequestInit = getOptions()

  return [info, init]
}
